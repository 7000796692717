.page-notfound {
  padding: 0 10%;
  margin: 170px auto;
  text-align: center;
  
  &__title {
    font-size: 24px;
    font-weight: bold;
  }
}
