
meta[name="viewport"] {
  background: #000000;
}
@media (--sm-viewport) {
  meta[name="viewport"] {
    background: #000768;
  }
}
@media (--md-viewport) {
  meta[name="viewport"] {
    background: #001025;
  }
}

@font-face {
  font-family: "Custom Yu Gothic";
  src: local("Yu Gothic");
  font-weight: 300;
}
@font-face {
  font-family: "Custom Yu Gothic";
  src: local("Yu Gothic");
  font-weight: 500;
}
@font-face {
  font-family: "Custom Yu Gothic";
  src: local("Yu Gothic");
  font-weight: bold;
}

html {
  font-family: "Yu Gothic", "Custom Yu Gothic", YuGothic, Meiryo, "メイリオ", sans-serif;
  line-height: 1.5;
}
body {

}

.sp-none {
  display: none!important;
  
  @media (--sm-viewport) {
    display: block!important;
  }
}
.pc-none {
  @media (--md-viewport) {
    display: none!important;
  }
}
.tbpc-none {
  @media (--sm-viewport) {
    display: none!important;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.main-contents {
  padding-top: 50px;
  
  @media (--sm-viewport) {
    padding-top: 60px;
  }
  @media (--md-viewport) {
    padding-top: 90px;
  }
}

.scroll-target {
  margin-top: -50px;
  padding-top: 50px;
  overflow: hidden;
  
  @media (--sm-viewport) {
    margin-top: -60px;
    padding-top: 60px;
  }
  @media (--md-viewport) {
    margin-top: -90px;
    padding-top: 90px;
  }
}


.preview-view {
  display: none;
}

.mw_wp_form_preview {
  .input-view {
    display: none;
  }
  .preview-view {
    display: block;
  }
  .required-text {
    display: none;
  }
  .required {
    display: none;
  }
}

