
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #f2f5f7;
  z-index: 1000;
  height: 50px;
  
  @media (--sm-viewport) {
    height: 60px;
  }
  @media (--md-viewport) {
    height: 90px;
  }
  
  &__container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    position: relative;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
    
    @media (--md-viewport) {
      box-shadow: none;
    }
  }
  
  
  &__togglemenu {
    position: absolute;
    right: var(--xs-container-margin);
    top: 10px;
    z-index: 1;
    
    @media (--sm-viewport) {
      top: 15px;
      right: var(--sm-container-margin);
    }
    @media (--md-viewport) {
      display: none;
    }
    
    .togglemenu {
      
      &__btn {
        width: 32px;
        height: 32px;
        display: block;
        border: none;
        padding: 0;
        margin: 0;
        z-index: 5001;
        
        @apply --button-reset;
        
        &.is-active {
          .togglemenu__line {
            span {
              background: #fff;
            }
            span:nth-child(1) {
              transform: translateY(6px) rotate(45deg);
            }
            span:nth-child(2) {
              transform: translateY(-6px) rotate(-45deg);
            }
          }
        }
      }
      &__txt {
        @apply --invisible;
      }
      &__line {
        span {
          display: block;
          width: 28px;
          height: 4px;
          position: absolute;
          background: #666666;
          transition: all 0.3s;
          left: 2px;
        }
        
        span:nth-child(1) {
          top: 8px;
        }
        span:nth-child(2) {
          bottom: 8px;
        }
        
        
      }
    }
  }
  
	&__sitename {
    display: flex;
    align-items: center;
    
    a {
      display: block;
      margin-top: 3px;
    }
    img {
      width: 100px;
      vertical-align: bottom;
    }
    @media (--sm-viewport) {
      img {
        width: 130px;
      }
    }
  }
  
  &__gnav {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 90%;
    height: 100vh;
    overflow: hidden;
    
    .gnav-open & {
      display: block;
    }
    
    @media (--sm-viewport) {
      width: 50%;
    }
    
    @media (--md-viewport) {
      display: flex;
      align-items: center;
      height: 100%;
      width: auto;
      position: relative;
      top: auto;
      right: auto;
    }
  }
}

.gnav {
  height: 100%;
  width: 100%;
  background: rgba(50,50,50,0.95);
  transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  
  .gnav-open & {
    transform: translateX(100%);
  }
  .gnav-open-anim & {
    transform: translateX(0);
  }
  
  @media (--md-viewport) {
    background: none;
  }
  
  &__lists {
    list-style: none;
    margin: 0;
    padding: 50px 0 0 0;
    
    @media (--sm-viewport) {
      padding: 60px 0 0 0;
    }
    @media (--md-viewport) {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0;
    }
  }
  
  &__list {
    margin: 0;
    padding: 0;
    
    @media (--md-viewport) {
      display: inline-block;
      margin-left: 10px;
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
    }
    
    a {
      position: relative;
      display: block;
      color: #fff;
      font-weight: bold;
      text-decoration: none;
      padding: 4% 5% 4% 5%;
      border-top: 1px solid rgba(255,255,255,0.5);
      
      @media (--md-viewport) {
        display: block;
        width: 100%;
        color: var(--accent-color);
        font-family: var(--font-nimbus);
        letter-spacing: 0.06em;
        text-decoration: none;
        padding: 10px;
        position: static;
        border: none;
        
        &:hover,
        &:focus {
          &:after {
            bottom: 0;
            height: 5px;
          }
        }
      }
      
      &:before {
        content: "\e905";
        @apply --unitec-iconset;
        display: block;
        color: #fff;
        font-size: 14px;
        
        position: absolute;
        right: var(--xs-container-margin);
        top: 50%;
        margin-top: -7px;
        
        @media (--sm-viewport) {
          right: var(--sm-container-margin);
        }
        @media (--md-viewport) {
          display: none;
        }
      }
      
      &:after {
        @media (--md-viewport) {
          content: "";
          display: block;
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 0;
          background: var(--accent-color);
          transition: all 0.15s ease-out;
        }
      }
    }
    
    &--company {
      html.page-company & {
        a:after {
          bottom: 0;
          height: 5px;
        }
      }
    }
    &--business {
      html.page-business & {
        a:after {
          bottom: 0;
          height: 5px;
        }
      }
    }
    &--environment {
      html.page-environment & {
        a:after {
          bottom: 0;
          height: 5px;
        }
      }
    }
    &--recruit {
      html.page-recruit & {
        a:after {
          bottom: 0;
          height: 5px;
        }
      }
    }
    &--column {
      html.page-column & {
        a:after {
          bottom: 0;
          height: 5px;
        }
      }
    }

    &--contact {
      margin-left: 2%;
      
      @media (--md-viewport) {
        margin-left: 30px;
      }
      
      a {
        background: linear-gradient(#40a0cc 10%, var(--accent-light-color) 10%);
        padding: 4% var(--xs-container-margin);
        border-top: none;
        
        @media (--sm-viewport) {
          padding: 4% var(--sm-container-margin);
        }
        
        @media (--md-viewport) {
          padding: 0 30px;
          color: #fff;
          display: flex;
          align-items: center;
          height: 100%;
          transition: opacity 0.3s;
          opacity: 1;
          
          &:after,
          &:before {
            display: none;
          }
          
          &:hover,
          &:focus {
            opacity: 0.8;
          }
        }
      }
    }
  }
  
}


