.un-button {
	display: inline-block;
	padding: 20px;
	color: #fff;
	position: relative;
	height: auto;
	margin: 0;
	text-align: center;
	line-height: 1.4;
	font-size: 16px;
	font-weight: bold;
	text-decoration: none;
	box-sizing: border-box;
	border: 1px solid var(--accent-color);
	color: var(--accent-color);
	background-color: #fff;
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OTUxMzM1MUU5RjUxMUU3QTFFMzk1NDIzQUQzOTVBMSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OTUxMzM1MkU5RjUxMUU3QTFFMzk1NDIzQUQzOTVBMSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5NTEzMzRGRTlGNTExRTdBMUUzOTU0MjNBRDM5NUExIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5NTEzMzUwRTlGNTExRTdBMUUzOTU0MjNBRDM5NUExIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+8Bou2AAAABpJREFUeNpiYGBgkGKAAEI08WDURMpNBAgwAD+MAotAsa9YAAAAAElFTkSuQmCC);
	overflow: hidden;
	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
	transition: box-shadow 0.5s cubic-bezier(0.23, 1, 0.32, 1);
	
	span {
		position: relative;
		z-index: 2;
	}
	
	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
		transform: translateY(100%);
		background: var(--accent-color);
		opacity: 0.8;
	}
	
	@media (--md-viewport) {
		&:hover,
		&:focus {
			color: #fff;
			box-shadow: 0 8px 22px -9px rgba(0,0,0,.5);

			&:before {
				transform: translateY(0);
			}
		}
  }
	
	
	&.un-button-full {
		width: 100%;
	}
	
	&.un-button-arr-left {
		&:after {
			@mixin icon e900;
			display: inline-block;
			position: absolute;
			left: 8%;
			top: 50%;
			margin-top: -0.5em;
		}
	}
	
	&.un-button-blue {
		color: #fff;
		background-color: var(--accent-color);
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDQUUwRDA4MUU5RjQxMUU3QTY3MEVGQTM4MjY3RDJDRSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDQUUwRDA4MkU5RjQxMUU3QTY3MEVGQTM4MjY3RDJDRSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkNBRTBEMDdGRTlGNDExRTdBNjcwRUZBMzgyNjdEMkNFIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNBRTBEMDgwRTlGNDExRTdBNjcwRUZBMzgyNjdEMkNFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+aNW7OwAAACRJREFUeNpi+P//vzEQMxCiGUEEMYAJiI2hbLz0qIl4AUCAAQDUkzHcgpNYOgAAAABJRU5ErkJggg==);
		color: #fff;
		
		&:before {
			background: #fff;
		}
		
		@media (--md-viewport) {
			&:hover,
			&:focus {
				color: var(--accent-color);
			}
	  }
		
	}
	&.un-button-pink {
		color: #fff;
		border: 1px solid #db4ca0;
		background-color: var(--sub-accent-color);
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDpDQUUwRDA4MUU5RjQxMUU3QTY3MEVGQTM4MjY3RDJDRSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpDQUUwRDA4MkU5RjQxMUU3QTY3MEVGQTM4MjY3RDJDRSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkNBRTBEMDdGRTlGNDExRTdBNjcwRUZBMzgyNjdEMkNFIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkNBRTBEMDgwRTlGNDExRTdBNjcwRUZBMzgyNjdEMkNFIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+aNW7OwAAACRJREFUeNpi+P//vzEQMxCiGUEEMYAJiI2hbLz0qIl4AUCAAQDUkzHcgpNYOgAAAABJRU5ErkJggg==);
		color: #fff;
		
		&:before {
			background: #fff;
		}
		
		@media (--md-viewport) {
			&:hover,
			&:focus {
				color: var(--sub-accent-color);
			}
	  }
		
	}
}

