.section-title {
	color: #2788c4;
	margin: 20px 0;
	word-break: break-all;

	@media (--sm-viewport) {
		padding: 40px 0 0 0;
		font-size: 42px;
	}
	@media (--md-viewport) {
		padding: 50px 0 0;
	}
	
	.en {
		position: relative;
		font-size: 33px;
		padding-left: 40px;
		line-height: 1.2;
		display: inline-block;
		
		&:before {
      content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			background: url(/assets/images/pages/index_fig_01.png);
			width: 31px;
			height: 40px;
    }
	}

	.ja {
		font-size: 18px;
		display: inline-block;
		
		@media (--sm-only-viewport) {
			display: inline-block;
		}
		@media (--sm-viewport) {
			font-size: 20px;
		}
		&:before {
			content: "・";
		}
	}
}