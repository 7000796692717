@font-face {
  font-family: 'unitec';
  src:  url('/assets/fonts/unitec.eot?fkcdo4');
  src:  url('/assets/fonts/unitec.eot?fkcdo4#iefix') format('embedded-opentype'),
    url('/assets/fonts/unitec.ttf?fkcdo4') format('truetype'),
    url('/assets/fonts/unitec.woff?fkcdo4') format('woff'),
    url('/assets/fonts/unitec.svg?fkcdo4#unitec') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'unitec' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
