
.footer {
	width: 100%;
	background-color: #edeff2;
	position: relative;
	z-index: 1;
	
	.footer__container {
		position: relative;
		padding: 0;
		margin: 0 auto;
		
		@media (--md-viewport) {
			padding: 50px 30px;
		}
	}
	
	.footernav {
		
		&__list {
			list-style: none;
			padding: 0;
			margin: 0;
			
			@media (--md-viewport) {
				display: flex;
				justify-content: space-between;
			}
		}
		&__item {
			@media (--md-viewport) {
				width: 14.2%;
			}
		}
		
		&__childlist {
			margin: 0;
			padding: 0;
			list-style: none;
			
			@media (--md-viewport) {
				padding-left: 15px;
			}
		}

		&__parent {
			
			@media (--md-viewport) {
				margin-bottom: 20px;
			}
			
			.footernav__link {
				position: relative;
				display: block;
				padding: 10px var(--xs-container-margin);
				background: #edeff2;
				text-decoration: none;
				border-top: 1px solid #bdbec0;
				color: #000;
				
				@media (--sm-viewport) {
					padding: 10px var(--sm-container-margin);
				}
				
				@media (--md-viewport) {
					font-size: 15px;
					font-weight: bold;
					padding: 0;
					border-top: none;
					background: none;
					padding-left: 20px;
					position: relative;
					
					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
				
				&:before {
					content: "\e905";
					@apply --unitec-iconset;
					display: block;
					color: var(--accent-color);
					font-size: 12px;
					
					position: absolute;
					right: var(--xs-container-margin);
					top: 50%;
					margin-top: -6px;
					
					@media (--sm-viewport) {
						right: var(--sm-container-margin);
					}
					
					@media (--md-viewport) {
						content: "\e905";
						display: inline-block;
						color: var(--accent-color);
						font-size: 12px;
						
						position: absolute;
						top: 0;
						left: 0px;
						margin: 6px 0 0 0;
						right: auto;
					}
				}
				
				&--toggle {
					&:before {
						content: "\e906";
					}
					&.is-active {
						&:before {
							content: "\e904";
							@media (--md-viewport) {
								content: "\e905";
							}
						}
					}
				}
			}
		}
		&__child {
			display: none;
			
			@media (--md-viewport) {
				display: block;
			}
			
			.footernav__link {
				position: relative;
				display: block;
				padding: 10px var(--xs-container-margin) 10px 8%;
				background: #e1e4e8;
				text-decoration: none;
				border-top: 1px solid #bdbec0;
				color: #000;
				font-size: 14px;
				
				@media (--sm-viewport) {
					padding: 10px var(--sm-container-margin) 10px 5%;
				}

				@media (--md-viewport) {
					text-decoration: none;
					font-size: 14px;
					font-weight: bold;
					padding: 0;
					border-top: none;
					background: none;
					font-weight: normal;
					margin-bottom: 7px;
					
					padding-left: 15px;
					position: relative;
					
					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
				
				&:before {
					content: "\e905";
					@apply --unitec-iconset;
					display: block;
					font-size: 10px;
					color: #868686;
					position: absolute;
					right: var(--xs-container-margin);
					top: 50%;
					margin-top: -5px;
					
					@media (--sm-viewport) {
						right: var(--sm-container-margin);
					}
					
					@media (--md-viewport) {
						content: "\e905";
						display: inline-block;
						color: #868686;
						font-size: 10px;

						position: absolute;
						top: 0;
						left: 0px;
						margin: 5px 0 0 0;
						right: auto;
					}
				}
			}
		}
		
		&__item--underline {
			border-bottom: 1px solid #bdbec0;
			@media (--md-viewport) {
				border: none;
			}
		}
		
		@media (--xssm-only-viewport) {
			&__item--except {
				.footernav__parent {
					display: inline-block;
					padding-left: var(--xs-container-margin);
					
					@media (--sm-viewport) {
						padding-left: var(--sm-container-margin);
					}
					
					.footernav__link {
						display: inline-block;
						border-top: none;
						padding: 10px 0 0;
						margin-right: 10px;
	
						&:before {
							display: inline-block;
							color: var(--accent-color);
							font-size: 12px;
							padding-right: 8px;
							position: relative;
							top: auto;
							right: auto;
							margin: 0;
						}
					}
				}
			}
		}
		
	}

	
	&__copyright {
		background-color: #414449;
		padding: 40px 30px;
		text-align: center;
		font-weight: bold;
		color: #fff;
		font-size: 14px;
	}
	
	&__logo {
		width: 170px;
		margin: auto;
		padding: 30px;
		
		@media (--md-viewport) {
			margin: 0;
		}
	}
	
	&__pagetop {
		position: absolute;
		bottom: -25px;
		right: var(--xs-container-margin);
		
		@media (--sm-viewport) {
			bottom: -35px;
			right: var(--sm-container-margin);
		}
		
		span {
      @apply --invisible;
    }
		
		a {
			display: block;
			width: 50px;
			height: 50px;
			background: #b9c0ca;
			text-align: center;
			text-decoration: none;
			transition: background 0.2s;
			
			@media (--sm-viewport) {
				width: 70px;
				height: 70px;
			}
			
			@media (--md-viewport) {
				&:hover,
				&:focus {
					background: #cfd3d9;
				}
			}
			
			
			&:before {
				content: "\e900";
				@apply --unitec-iconset;
				line-height: 50px;
				color: #fff;
				font-size: 20px;
				
				@media (--sm-viewport) {
					line-height: 70px;
					font-size: 25px;
				}
			}
		}
	}
}


