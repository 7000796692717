:root {
  --accent-color: #2788c4;
  --accent-light-color: #52aed9;
  --sub-accent-color: #ff5096;
  --font-nimbus: "nimbus-sans", sans-serif;
  
  --gutter-width: 10px;
  --outer-margin: 20px;
  --gutter-compensation: calc((var(--gutter-width) * 0.5) * -1);
  --half-gutter-width: calc((var(--gutter-width) * 0.5));
  
  --xs-container-margin: 5%;
  --sm-container-margin: 3%;
  
  --max-contents-width: 1200px;
  --sm-min: 768px;
  --md-min: 1025px;
  --lg-min: 1200px;
  --container-sm: calc(var(--sm-min) + var(--gutter-width));
  --container-md: calc(var(--md-min) + var(--gutter-width));
  --container-lg: calc(var(--lg-min) + var(--gutter-width));

  --button-reset: {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  };
  
  --invisible: {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  --unitec-iconset: {
    display:block;
  	font-family: 'unitec' !important;
  	speak: none;
  	font-style: normal;
  	font-weight: normal;
  	font-variant: normal;
  	text-transform: none;
  	line-height: 1;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
  }
}

/* mobile only */
@custom-media --xs-viewport only screen and (max-width: 767px);
/* mobile and tablet */
@custom-media --xssm-only-viewport only screen and (max-width: 1024px);
/* tablet */
@custom-media --sm-viewport only screen and (min-width: 768px);
/* tablet only */
@custom-media --sm-only-viewport only screen and (min-width: 768px) and (max-width: 1024px);
/* desktop */
@custom-media --md-viewport only screen and (min-width: 1025px);
/* large desktop */
@custom-media --lg-viewport only screen and (min-width: 1200px);
/* mobile - desktop */
@custom-media --xs-lg-viewport only screen and (max-width: 1199px);
